import Vue from "vue";
import Vuex from "vuex";
import account from "./account";
import advancePayment from "./advance-payment";
import area from "./area";
import authorized from "./authorized";
import asset from "./asset";
import bank from './bank';
import branch from './branch';
import basic from './basic-setting';
import book from './book';
import bookIssue from './book-issue';
import bonus from './bonus';
import bankType from './bank-type';
import board from './board';
import category from './category';
import collection from './collection';
import country from './country';
import department from './department';
import designation from './designation';
import employee from './employee';
import employeeType from './employee-type';
import exam from './exam';
import examHead from './exam-head';
import feesAccount from './fees-account';
import feesSetting from './fees-setting';
import holiday from './holiday';
import hostel from './hostel';
import hostelAdmission from './hostel-admission';
import layout from './menu';
import leave from './leave';
import librarySubject from './library-subject';
import libraryMember from './library-member';
import month from './month';
import product from './product';
import purchase from './purchase';
import position from './position';
import result from './result';
import room from './room';
import recipient from './recipient';
import routine from './routine';
import relation from "./relation";
import snackbar from './snackbar';
import sale from './sale';
import section from './section';
import session from './session';
import studentClass from './student-class';
import student from './student';
import subject from './subject';
import shelf from './shelf';
import supplier from './supplier';
import seat from './seat';
import transport from './transport';
import user from './user';
import unit from './unit';
import notice from './notice';
import year from './year';
import pf from './pf';
import period from './period';


Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		host: process.env.NODE_ENV === 'production' ? 'https://newedu.bigerpbd.com/backend/api' : 'http://localhost:8000/api',
		hostName: process.env.NODE_ENV === 'production' ? 'https://newedu.bigerpbd.com/backend/' : 'http://localhost:8000/',
	},

	getters: {},

	mutations: {},

	actions: {},

	modules: {
		account,
		advancePayment,
		area,
		authorized,
		asset,
		bank,
		branch,
		basic,
		book,
		bookIssue,
		bonus,
		bankType,
		board,
		category,
		collection,
		country,
		department,
		designation,
		employee,
		employeeType,
		exam,
		examHead,
		feesAccount,
		feesSetting,
		holiday,
		hostel,
		hostelAdmission,
		layout,
		leave,
		librarySubject,
		libraryMember,
		month,
		product,
		purchase,
		position,
		result,
		room,
		recipient,
		routine,
		relation,
		sale,
		studentClass,
		snackbar,
		section,
		session,
		subject,
		student,
		shelf,
		supplier,
		seat,
		transport,
		user,
		unit,
		notice,
		year,
		pf,
		period,
	},
});
