export default {
    namespaced: true,

    state: () => {
        return {
            loading: false,
            months: [],
        }
    },

    getters: {
        loading(state) {
            return state.loading;
        },

        months(state) {
            return state.months.map((item, sl) => {
                item.sl = sl + 1;
                item.display_text = `${item.name} - ${item.year}`;
                return item;
            });
        }
    },

    mutations: {
        setLoading(state, loading) {
            state.loading = loading
        },

        setMonth(state, months) {
            state.months = months
        },
    },

    actions: {
        async getMonths(context) {
            context.commit('setLoading', true);
        
            await axios.get(`${this.state.host}/get-months`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setMonth', res.data.months);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setLoading', false);
        },

        async saveMonth(context, payload) {
            let isSuccess = false;

            let url = '';
            if(payload.id != null) {
                url = 'update-month';
            } else {
                url = 'add-month';
                delete payload.id
            }

            await axios.post(`${this.state.host}/${url}`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getMonths');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async deleteMonth(context, id) {
            let isSuccess = false;

            await axios.delete(`${this.state.host}/delete-month/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getMonths');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        }
    }
}
