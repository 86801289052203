export default {
    namespaced: true,

    state: () => {
        return {
            loading: false,
            advancePayments: [],
        }
    },

    getters: {
        loading(state) {
            return state.loading;
        },

        advancePayments(state) {
            return state.advancePayments.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        }
    },

    mutations: {
        setLoading(state, loading) {
            state.loading = loading
        },

        setAdvancePayment(state, advancePayments) {
            state.advancePayments = advancePayments
        },
    },

    actions: {
        async getAdvancePayment(context, payload) {
            context.commit('setLoading', true);
        
            await axios.post(`${this.state.host}/get-advance-payments`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setAdvancePayment', res.data.payments);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setLoading', false);
        },

        async saveAdvancePayment(context, payload) {
            let response = {isSuccess: false};
       
            let url = '';
            if(payload.id != null) {
                url = 'update-advance-payment';
            } else {
                url = 'add-advance-payment';
                delete payload.id
            }

            await axios.post(`${this.state.host}/${url}`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                response.isSuccess = true;
                response.paymentId = res.data.paymentId;
                this.dispatch('snackbar/success', res.data.message);
                // context.dispatch('getAdvancePayment');
            })
            .catch(error => { 
                response.isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return response;
        },

        async deleteAdvancePayment(context, id) {
            let isSuccess = false;

            await axios.delete(`${this.state.host}/delete-advance-payment/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getAdvancePayment');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        }
    }
}
